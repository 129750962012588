<template>
  <!-- 媒体号弹窗 使用:运营计划/爱写笔记 -->
  <a-modal
    v-model="showMedia"
    title="选择媒体号"
    :dialog-style="{ top: '20px' }"
    @cancel="handleMediaCancel"
    @ok="handleMediaOk"
  >
    <a-row type="flex" justify="space-between" style="margin-bottom: 5px">
      <a-col :span="12" style="margin-right: 5px">
        <a-select
          style="width: 100%"
          v-model="form.creator"
          placeholder="请选择添加人"
          show-search
          @change="getMediaList"
          option-filter-prop="children"
          allowClear
        >
          <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :flex="1">
        <a-input-search
          style="width: 100%"
          placeholder="媒体昵称/ID"
          v-model.trim="form.nickname"
          @search="handleSearch"
        ></a-input-search>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" style="margin-bottom: 5px">
      <a-col :span="12" style="margin-right: 5px">
        <a-select
          style="width: 100%"
          v-model="form.personalityType"
          placeholder="人设"
          show-search
          @change="getMediaList"
          option-filter-prop="children"
          allowClear
        >
          <a-select-option v-for="(item, index) in personalityTypeList" :key="index" :value="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :flex="1">
        <a-select
          style="width: 100%"
          v-model="form.masterAccountUsername"
          placeholder="请选择负责人"
          show-search
          option-filter-prop="children"
          @change="handleSearch"
          allowClear
        >
          <a-select-option v-for="item in currentManagerList" :key="item.username" :value="item.username">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" style="margin-bottom: 5px">
      <a-col :span="24" style="margin-right: 5px">
        <a-select
          v-model="form.groupId"
          :disabled="limited"
          placeholder="请选择分组"
          style="width: 100%"
          show-search
          option-filter-prop="children"
          @change="handleSearch"
          allowClear
        >
          <a-select-option v-for="item in allGroupList" :key="item.id" :value="item.id">{{
            item.groupName
          }}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <div class="media-list">
        <div
          :class="['media-item', { 'current-item': currentMedia.id === value.id }]"
          v-for="value in mediaList"
          :key="value.id"
          @click="handleMediaClick(value)"
        >
          <div class="media-left">
            <div class="left-left" v-show="value.avatar">
              <img :src="value.avatar" alt="头像" referrerpolicy="no-referrer" />
            </div>
            <div class="left-right">
              <div style="margin-left: 15px">{{ value.nickname }}</div>
              <div class="line-flex right-txt">
                <div class="logo" v-show="value.code">
                  <img src="@/assets/icon/xhs_logo.png" alt="" />
                </div>
                <div>{{ value.code || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="media-right">
            <a-tooltip>
              <template slot="title">
                <div>授权时间：{{ value.p1OauthTime || '-' }}</div>
              </template>
              <div class="sync-succ" v-show="value.p1OauthStatus === 1">
                笔记数据
                <a-icon style="margin-left: 5px" type="check-circle" />
              </div>
            </a-tooltip>
            <a-tooltip>
              <template slot="title">
                <div>失效时间：{{ value.p1OauthExpireTime || '-' }}</div>
              </template>
              <div class="sync-err" v-show="value.p1OauthStatus !== 1">
                笔记数据<a-icon style="margin-left: 5px" type="exclamation-circle" />
              </div>
            </a-tooltip>
          </div>
        </div>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import { personalityTypeList } from '../constant';
import api from '@/api/xhsAgencyApi';

export default {
  props: {
    limited: {
      default: false,
    },
    limitedName: {
      default: undefined,
    },
  },
  data() {
    return {
      personalityTypeList,
      showMedia: true,
      mediaList: [],
      creatorList: [],
      currentMedia: {},
      currentManagerList: [],
      allGroupList: [],
      form: {},
      spinning: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // this.form.creator = this.$router.app.user.username;
      this.form.masterAccountUsername = this.$router.app.user.username || undefined;
      this.getCreatorList();
      this.getCurrentManagerList();
      await this.getAllGroupList();
      if (this.limited) {
        const find = this.allGroupList.find((v) => v.groupName == this.limitedName);
        if (find?.id) this.form.groupId = find.id;
      }
      this.getMediaList();
    },
    async getMediaList() {
      const params = {
        page: 1,
        size: 100,
        status: 'NORMAL',
        ...this.form,
      };
      this.spinning = true;
      await this.handleRequest(
        'getAgencyList',
        (data) => {
          this.mediaList = data.list;
        },
        params
      ).finally(() => (this.spinning = false));
    },
    handleMediaCancel() {
      this.showMedia = false;
      this.$emit('closeModal');
    },
    handleMediaClick(value) {
      // if (value.p1OauthStatus !== 1) {
      //   return;
      // } else {
      this.currentMedia = value;
      // }
    },
    handleSearch() {
      this.currentMedia = {};
      this.getMediaList();
    },
    async getCreatorList() {
      this.handleRequest('getMediaCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    getCurrentManagerList() {
      this.handleRequest('getCurrentManagerList', (data) => {
        this.currentManagerList = data;
      });
    },
    getAllGroupList() {
      return new Promise((resolve) => {
        const params = {
          pageNum: 1,
          pageSize: 9999,
          groupScene: 6,
        };
        this.handleRequest(
          'getMediaGroupList',
          (data) => {
            this.allGroupList = data.list;
          },
          params
        ).finally(() => resolve());
      });
    },
    async handleMediaOk() {
      if (!this.currentMedia.code) return this.$message.info('请选择媒体号');
      this.$emit('mediaConfirm', this.currentMedia);
      this.handleMediaCancel();
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.media-list {
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;

  .enable-item {
    cursor: pointer;
  }

  .disabled-item {
    cursor: not-allowed;
  }

  .current-item {
    background-color: rgba(128, 42, 240, 0.531) !important;
  }

  .media-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 10px;
    background-color: #f2f2f2;

    .media-left {
      display: flex;

      .left-left {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .left-right {
        flex: 1;

        .right-txt {
          justify-content: start;

          .logo {
            width: 20px;
            height: 20px;
            border-radius: 10px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #00152984;

    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #0015294c;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }

  .line-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.sync-err {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  margin: 0 auto;
  width: 100px;
  padding-left: 15px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}
</style>
